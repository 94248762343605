import * as React from "react";

interface Props {
  errorMessage?: string;
  isRetryShow?: boolean;
  onRetry?: () => void;
}

const defaultRetry = (): void => {
  window.location.reload();
};

const PageErrorContainer: React.FC<Props> = ({ errorMessage, isRetryShow = false, onRetry = defaultRetry }) => {
  return (
    <>
      <div className="text-center">
        <div className="mx-auto">{"We didn't get a response from the server due to network or server error"}</div>
        {errorMessage && <div className="w-25 mx-auto mt-3">{errorMessage}</div>}
        <div className="mt-3">
          {isRetryShow && (
            <button className="btn btn-discreet-dark ml-2" onClick={onRetry}>
              Retry
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default PageErrorContainer;
