import * as React from "react";

export interface Props {
  label: string;
  className?: string;
}

const LabelValueComponent: React.FC<Props> = ({ label, children, className = "" }) =>
  children ? (
    <div className={`d-flex mb-1 ${className}`}>
      <div className="text-secondary" style={{ minWidth: "150px" }}>
        {label}
      </div>
      <div>{children}</div>
    </div>
  ) : null;

export default LabelValueComponent;
