import * as React from "react";
import AddressViewer from "./Address/AddressViewer";
import LabelValueComponent from "../common/components/LabelValue/LabelValueComponent";
import { AccountViewerWidget } from "../common/typings/accounts.typings";

interface Props {
  account: AccountViewerWidget;
}

const AccountViewerBody: React.FC<Props> = ({ account }) => {
  const {
    countryIso3,
    level,
    bdrCommercialId,
    bdrLegalId,
    rctId,
    dec,
    dr,
    typology,
    parentGroup,
    mainAddress,
  } = account;
  return (
    <div>
      <LabelValueComponent label="Country">{countryIso3}</LabelValueComponent>
      <LabelValueComponent label="Level">{level}</LabelValueComponent>
      <LabelValueComponent label="Ccial BDR ID">{bdrCommercialId}</LabelValueComponent>
      <LabelValueComponent label="Legal BDR ID">{bdrLegalId}</LabelValueComponent>
      <LabelValueComponent label="RCT ID">{rctId}</LabelValueComponent>
      <LabelValueComponent label="DEC">{dec}</LabelValueComponent>
      <LabelValueComponent label="DR">{dr}</LabelValueComponent>
      <LabelValueComponent label="Typology">{typology}</LabelValueComponent>
      <LabelValueComponent label="Parent group">{parentGroup}</LabelValueComponent>
      {mainAddress && (
        <LabelValueComponent label="Address">
          <AddressViewer mainAddress={mainAddress} />
        </LabelValueComponent>
      )}
    </div>
  );
};

export default AccountViewerBody;
