import { toUpper } from "lodash-es";
import * as React from "react";
import { getInitials } from "../utils/strings/stringUtils";

interface Props {
  domainName?: string;
  accountName: string;
}

const logoBaseUrl = "https://logo.clearbit.com";

const AccountViewerHeader: React.FC<Props> = ({ domainName, accountName }) => {
  const [errorImage, setImageError] = React.useState(false);

  return (
    <div className="d-flex mb-2 align-items-center">
      {!errorImage && (
        <img
          className="mr-2"
          src={`${logoBaseUrl}/${domainName}`}
          onError={() => setImageError(true)}
          style={{ maxWidth: "60px", maxHeight: "60px" }}
          alt={getInitials(accountName)}
        />
      )}

      <h4 className="m-0">{toUpper(accountName)}</h4>
    </div>
  );
};

export default AccountViewerHeader;
