import * as React from "react";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { AccountViewerScope } from "../../common/sgConnectScopes";
import { getClientById, getDomainByClientId } from "../../api/accounts/accounts.api";
import { mapToAccountViewerWidget, mapToDomainName } from "../../common/mappers/account.mapper";
import {
  accountViewerReducer,
  AccountViewerState,
  getClientByIdAction,
  initialAccountViewerState,
} from "./accountViewer.reducer";
import { AccountProperty } from "../../api/accounts/accounts.api.typings";

export interface DispatchProps {
  getClientById: (id: string) => void;
}

export interface AccountViewContextProviderProps {
  context: AccountViewerState;
  actions: DispatchProps;
}

const properties: AccountProperty[] = [
  "bdrId",
  "country",
  "mainAddress",
  "typology",
  "clientIds",
  "customerRepresentative",
  "computedClientRootId",
];

export const getActions = (fetch: FetchFn, dispatch: React.Dispatch<any>): DispatchProps => {
  return {
    getClientById: (id: string) => {
      dispatch(getClientByIdAction.started({ id }));
      Promise.all([
        getClientById(fetch, id, { properties }),
        getDomainByClientId(fetch, id)
          .then(({ domains }) => mapToDomainName(domains))
          .catch(() => ""),
      ])
        .then(([client, domainName]) => mapToAccountViewerWidget(client, domainName))
        .then(result => dispatch(getClientByIdAction.done({ result, params: { id } })))
        .catch(err => dispatch(getClientByIdAction.failed({ error: err.message, params: { id } })));
    },
  };
};

export const AccountViewerContext = React.createContext<AccountViewContextProviderProps | null>(null);

interface Props {
  initialState?: AccountViewerState;
  actions?: DispatchProps;
}

const AccountViewerContextProvider: React.FC<Props> = ({
  children,
  initialState = initialAccountViewerState,
  actions,
}) => {
  const fetch = useSgConnectFetch(AccountViewerScope).fetch as FetchFn;
  const [state, dispatch] = React.useReducer(accountViewerReducer, initialState);
  const actionsCached = React.useMemo(() => actions ?? getActions(fetch, dispatch), [actions, fetch]);

  return (
    <AccountViewerContext.Provider value={{ context: state, actions: actionsCached }}>
      {children}
    </AccountViewerContext.Provider>
  );
};

export default AccountViewerContextProvider;
