import * as React from "react";
import { Address } from "../../api/accounts/accounts.api.typings";

interface Props {
  mainAddress: Address;
}

const AddressViewer: React.FC<Props> = ({ mainAddress }) => {
  const { street1, street2, street3, zipCode, city, country } = mainAddress;
  return (
    <div>
      <div>{street1}</div>
      <div>{street2}</div>
      <div>{street3}</div>
      <div>
        {zipCode} {city}
      </div>
      <div>{country}</div>
    </div>
  );
};

export default AddressViewer;
