import * as React from "react";
import PageErrorContainer from "../common/components/Error/PageErrorContainer";
import Loading from "../common/components/Loading/Loading";
import AccountViewerBody from "./AccountViewerBody";
import AccountViewerHeader from "./AccountViewerHeader";
import { AccountViewContextProviderProps, AccountViewerContext } from "./contextProvider/AccountViewerContextProvider";

interface Props {
  accountId: string;
}

const AccountViewerContent: React.FC<Props> = ({ accountId }) => {
  const {
    actions,
    context: { account, isFetching, errorMessage },
  } = React.useContext(AccountViewerContext) as AccountViewContextProviderProps;

  const handleOnLoad = React.useCallback(() => {
    actions.getClientById(accountId);
  }, [actions, accountId]);

  React.useEffect(() => {
    handleOnLoad();
  }, [handleOnLoad]);

  const isErrorOrFetchingClassName =
    errorMessage || isFetching ? "d-flex justify-content-center align-items-center" : "";

  return (
    <div
      style={{ minWidth: "350px", minHeight: "350px", overflowY: "auto" }}
      className={`p-3 border ${isErrorOrFetchingClassName}`}
    >
      {errorMessage && <PageErrorContainer isRetryShow={true} onRetry={handleOnLoad} />}
      <Loading isLoading={isFetching} />
      {account && !isFetching && !errorMessage && (
        <>
          <AccountViewerHeader domainName={account.domainName} accountName={account.name} />
          <AccountViewerBody account={account} />
        </>
      )}
    </div>
  );
};

export default AccountViewerContent;
