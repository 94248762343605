import actionCreatorFactory, { AnyAction, isType } from "typescript-fsa";
import { AccountViewerWidget } from "../../common/typings/accounts.typings";

const actionCreator = actionCreatorFactory("accountViewer");

export const getClientByIdAction = actionCreator.async<{ id: string }, AccountViewerWidget, string>("GET_CLIENT_BY_ID");

export interface AccountViewerState {
  account?: AccountViewerWidget;
  isFetching: boolean;
  errorMessage: string;
}

export const initialAccountViewerState = {
  account: undefined,
  isFetching: false,
  errorMessage: "",
};

export const accountViewerReducer = (
  state: AccountViewerState = initialAccountViewerState,
  action: AnyAction
): AccountViewerState => {
  if (isType(action, getClientByIdAction.started)) {
    return { ...state, account: undefined, isFetching: true, errorMessage: "" };
  }
  if (isType(action, getClientByIdAction.done)) {
    return { ...state, account: action.payload.result, isFetching: false, errorMessage: "" };
  }
  if (isType(action, getClientByIdAction.failed)) {
    return { ...state, account: undefined, isFetching: false, errorMessage: action.payload.error };
  }
  return state;
};
