import * as React from "react";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";
import AccountViewerContextProvider from "./contextProvider/AccountViewerContextProvider";
import AccountViewerContent from "./AccountViewerContent";
import { WithSGB4 } from "../common/components/WithSGB4";
import WidgetTracker from "../common/components/WidgetTracker";

interface Props {
  id: string;
}

const IcAccountViewerWidget: React.FC<Props> = ({ id }) => {
  return (
    <WithSGB4>
      <AccountViewerContextProvider>
        <AccountViewerContent accountId={id} />
      </AccountViewerContextProvider>
      <WidgetTracker widgetName={"ic-account-viewer"} />
    </WithSGB4>
  );
};

widgetize(
  "ic-account-viewer",
  IcAccountViewerWidget,
  {
    id: WidgetPropsMapping.asString(),
  },
  { neverInjectGlobalCss: true }
);
